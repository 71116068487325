import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { useState } from 'react';

import ExitIcon from '@/assets/icons/gray-x-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';
import { CreateColumnFn } from '@/pages/overview/contract-clauses/common/utils';
import { ClauseIdentificationStep } from '@/pages/overview/contract-clauses/custom-column-wizard/ClauseIdentificationStep';
import { CustomColumnWizardSelect } from '@/pages/overview/contract-clauses/custom-column-wizard/CustomColumnWizardSelect';
import { InformationExtractionStep } from '@/pages/overview/contract-clauses/custom-column-wizard/InformationExtractionStep';
import { trpcReact } from '@/utils/trpc';

interface CustomColumnWizard {
  isWizardOpen: boolean;
  closeWizard: (val: boolean) => void;
  createContractClauseColumn: CreateColumnFn;
  contractClauseListRef: React.RefObject<HTMLDivElement>;
}
export const CustomColumnWizard = ({
  isWizardOpen,
  closeWizard,
  createContractClauseColumn,
  contractClauseListRef,
}: CustomColumnWizard) => {
  const { matter } = useOverview();
  const [customColumnStep, setCustomColumnStep] = useState('select');

  const handleCloseWizard = () => {
    closeWizard(true);
    setCustomColumnStep('select');
  };

  const saveCustomColumn = trpcReact.dataRoom.saveCustomContractClause.useMutation();

  const createCustomColumn = (
    customType: string,
    customName: string,
    customLabel: string,
    customDescription: string,
    customElements: string,
    otherText: string,
    wideCol: boolean,
  ) => {
    saveCustomColumn.mutate(
      {
        clientMatterId: matter.id,
        type: customType,
        name: customName,
        label: customLabel,
        elements: customElements,
        other: otherText,
        description: customDescription,
      },
      {
        onSuccess: () => {
          createContractClauseColumn(customType, wideCol, true);
          handleCloseWizard();
          if (contractClauseListRef.current) {
            contractClauseListRef.current.scrollTo({
              left: contractClauseListRef.current.scrollWidth + 100,
              behavior: 'smooth',
            });
          }
        },
        onError: () => {
          alert(`Could not find data`);
        },
      },
    );
  };

  return (
    <Dialog
      open={isWizardOpen}
      onClose={handleCloseWizard}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      data-testid="custom-column-wizard"
    >
      <DialogPanel className="flex flex-col gap-8 rounded-[16px] bg-[#121112] p-8">
        <div className="flex justify-between">
          <DialogTitle className="text-[24px] font-medium text-[#EAE6EA]">
            Create Column
          </DialogTitle>
          <MarveriIcon icon={ExitIcon} onClick={handleCloseWizard} iconStyle="" iconType="other" />
        </div>
        {customColumnStep === 'select' && (
          <CustomColumnWizardSelect setCustomColumnStep={setCustomColumnStep} />
        )}
        {customColumnStep === 'identification' && (
          <ClauseIdentificationStep
            setCustomColumnStep={setCustomColumnStep}
            createCustomColumn={createCustomColumn}
            isSubmitting={saveCustomColumn.isLoading}
          />
        )}
        {customColumnStep === 'extraction' && (
          <InformationExtractionStep
            setCustomColumnStep={setCustomColumnStep}
            createCustomColumn={createCustomColumn}
            isSubmitting={saveCustomColumn.isLoading}
          />
        )}
      </DialogPanel>
    </Dialog>
  );
};
