import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';

import ExitIcon from '@/assets/icons/gray-x-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';
import { CustomColumnType } from '@/pages/overview/contract-clauses/common/utils';
import { trpcReact } from '@/utils/trpc';

interface InformationExtractionRerunProps {
  rerunDialogVisibility: string | undefined;
  setRerunDialogVisibility: (val: string | undefined) => void;
  customColumnToEdit?: CustomColumnType;
}

export const InformationExtractionRerun = ({
  rerunDialogVisibility,
  setRerunDialogVisibility,
  customColumnToEdit,
}: InformationExtractionRerunProps) => {
  const { matter } = useOverview();
  const [infoToExtract, setInfoToExtract] = useState(customColumnToEdit?.name || '');
  const [descriptionOfInfo, setDescriptionOfInfo] = useState(customColumnToEdit?.description || '');
  const [identificationElements, setIdentificationElements] = useState(
    customColumnToEdit?.elements || '',
  );
  const [otherNotes, setOtherNotes] = useState(customColumnToEdit?.other || '');
  const [responseFormat, setResponseFormat] = useState(customColumnToEdit?.response_format || '');

  const reRunCustomColumn = trpcReact.dataRoom.editAndRerunCustomContractClause.useMutation();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const initialValues = useRef({
    name: customColumnToEdit?.name || '',
    description: customColumnToEdit?.description || '',
    elements: customColumnToEdit?.elements || '',
    other: customColumnToEdit?.other || '',
    responseFormat: customColumnToEdit?.response_format || '',
  });

  useEffect(() => {
    const hasChanged =
      infoToExtract !== initialValues.current.name ||
      descriptionOfInfo !== initialValues.current.description ||
      identificationElements !== initialValues.current.elements ||
      otherNotes !== initialValues.current.other ||
      responseFormat !== initialValues.current.responseFormat;

    setIsButtonDisabled(!hasChanged);
  }, [descriptionOfInfo, identificationElements, infoToExtract, otherNotes, responseFormat]);

  const handleCloseRerunDialog = () => {
    setRerunDialogVisibility(undefined);
  };

  const reRunInformationExtraction = () => {
    if (isButtonDisabled) return;
    if (customColumnToEdit) {
      reRunCustomColumn.mutate(
        {
          clientMatterId: matter.id,
          type: 'INFORMATION_EXTRACTION',
          name: infoToExtract,
          label: infoToExtract,
          elements: identificationElements,
          other: otherNotes,
          description: descriptionOfInfo,
          response_format: responseFormat,
          customContractClauseDefinitionId: customColumnToEdit.id,
        },
        {
          onSuccess: () => handleCloseRerunDialog(),
          onError: () => {
            alert(`An error occurred please try again`);
          },
        },
      );
    }
  };

  return (
    <Dialog
      open={rerunDialogVisibility === 'extraction'}
      onClose={handleCloseRerunDialog}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      data-testid="custom-column-wizard"
    >
      <DialogPanel className="flex flex-col gap-8 rounded-[16px] bg-[#121112] p-8">
        <div className="flex justify-between">
          <DialogTitle className="text-[24px] font-medium text-[#EAE6EA]">Edit Column</DialogTitle>
          <MarveriIcon
            icon={ExitIcon}
            onClick={handleCloseRerunDialog}
            iconStyle=""
            iconType="other"
          />
        </div>
        <div
          className="flex max-w-[592px] flex-col  gap-6"
          data-testid="information-extraction-step"
        >
          <div className="text-[14px] font-medium text-[#EAE6EA]">
            {`Please provide details to help Marveri identify the clause. 'Clause Type' and 'Clause Description' are required fields. The more information you provide, the more accurate the results will be. Be sure to review all output carefully.`}
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">
              Information to be Extracted*
            </h2>
            <span className="text-[14px] font-normal text-N400">
              A brief label for the type of information to be extracted from the documents.
            </span>
            <input
              className="mt-2 h-[40px] resize-none rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500"
              value={infoToExtract}
              placeholder="e.g. Effective Date"
              onChange={(event) => setInfoToExtract(event.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Description*</h2>
            <span className="text-[14px] font-normal text-N400">
              A description of the information to be extracted.
            </span>
            <textarea
              className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
              value={descriptionOfInfo}
              placeholder='e.g. The "Effective Date" of a contract is the date on which the contract becomes legally active.'
              onChange={(event) => setDescriptionOfInfo(event.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Identification Elements</h2>
            <span className="text-[14px] font-normal text-N400">
              A list of key elements that will aid in identifying the information to be extracted.
            </span>
            <textarea
              className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
              value={identificationElements}
              placeholder='e.g. Language explicitly identifying the "Effective Date"'
              onChange={(event) => setIdentificationElements(event.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Other Notes</h2>
            <span className="text-[14px] font-normal text-N400">
              Any additional notes that may aid in identifying and/or extracting the information at
              issue.
            </span>
            <textarea
              className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
              value={otherNotes}
              placeholder="e.g. The effective date may be different from the signing date or the stated date in some cases."
              onChange={(event) => setOtherNotes(event.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Response Format</h2>
            <span className="text-[14px] font-normal text-N400">
              The specific format in which the extracted information should be provided.
            </span>
            <textarea
              className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
              value={responseFormat}
              placeholder="e.g. MM/DD/YYYY format"
              onChange={(event) => setResponseFormat(event.target.value)}
            />
          </div>
          <div className="flex w-full justify-end gap-2">
            <div
              className="cursor-pointer rounded-full px-[18px] py-3 text-[14px] font-medium text-N200 hover:bg-[#292829] hover:text-[#EAE6EA]"
              onClick={handleCloseRerunDialog}
            >
              Back
            </div>
            <div
              className={`${isButtonDisabled ? 'cursor-default bg-[#353336]' : 'cursor-pointer bg-[#EAE6EA] hover:bg-N200 '} rounded-full px-[18px] py-3 text-[14px] font-medium text-[#121112]`}
              onClick={reRunInformationExtraction}
            >
              Save & Run
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
