import ExtractionIcon from '@/assets/icons/cc-extraction-icon.svg';
import IdentificationIcon from '@/assets/icons/cc-identification-icon.svg';

interface CustomColumnWizardSelectProps {
  setCustomColumnStep: (val: string) => void;
}

export const CustomColumnWizardSelect = ({
  setCustomColumnStep,
}: CustomColumnWizardSelectProps) => {
  return (
    <div className="flex gap-4" data-testid="custom-column-wizard-select">
      <div
        className="group flex h-[176px] w-[287px] cursor-pointer flex-col items-center rounded-[8px] bg-[#19181A] px-4 py-8 font-medium hover:bg-[#353336]"
        onClick={() => setCustomColumnStep('extraction')}
      >
        <div className="mb-3 rounded-[40px] bg-[#353336] p-3 group-hover:bg-N500">
          <img src={ExtractionIcon} />
        </div>
        <h2 className="mb-1 text-[14px] text-[#FAFAFA]">Information Extraction</h2>
        <span className="text-center text-[12px] text-[#ABABB5]">
          Extract specific information from the contract
        </span>
      </div>
      <div
        className="group flex h-[176px] w-[287px] cursor-pointer flex-col items-center rounded-[8px] bg-[#19181A] px-4 py-8 font-medium hover:bg-[#353336]"
        onClick={() => setCustomColumnStep('identification')}
      >
        <div className="mb-3 rounded-[40px] bg-[#353336] p-3 group-hover:bg-N500">
          <img src={IdentificationIcon} />
        </div>
        <h2 className="mb-1 text-[14px] text-[#FAFAFA]">Clause Identification</h2>
        <span className="text-center text-[12px] text-[#ABABB5]">
          Determine whether the contract contains a specific clause or provision
        </span>
      </div>
    </div>
  );
};
