import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FileIcon from '@/assets/images/new-file-icon.svg';
import { ContextMenu } from '@/components/ContextMenu';
import { Droppable } from '@/components/Droppable';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useContextMenu } from '@/contexts/overview/dataroom/useContextMenu';
import { useRedlinesContext } from '@/contexts/overview/redlines/utils';
import { usePresignedUrl } from '@/hooks/usePresignedUrl';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import { DraggableRedlineViewFile } from '@/pages/overview/redline/diffing/DraggableRedlineViewFile';
import { PdfViewer } from '@/pages/pdf-viewer/PdfViewer';

export const RedlineDiffingSidebar = () => {
  const [displayedFiles, setDisplayedFiles] = useState<
    {
      name: string;
      distance: number;
      s3Key: string;
    }[]
  >([]);
  const { isContextMenuVisible, setIsContextMenuVisible, position, setPosition } = useContextMenu();
  const [hoveredFile, setHoveredFile] = useState<string | undefined>();
  const { redlineName } = useParams();
  const navigate = useNavigate();
  const [signedUrl, setSelectedFileName, selectedFileName, resetFileUrl] = usePresignedUrl();
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [dragFileName, setDragFileName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [pdfDisplayName, setPdfDisplayName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const {
    comparingFiles,
    setComparingFiles,
    templateFileUrl,
    setTemplateFileUrl,
    focusedFiles,
    focusedOperation,
    allFileDifferences,
    loading,
  } = useRedlinesContext();

  const handleExportClick = () => {
    if (comparingFiles.length > 2) {
      setErrorMessage('Please select only two documents for export.');
      return;
    }

    // ✅ ADD YOUR EXPORT LOGIC HERE
    console.log("Exporting");
  };

  const {
    matter: { dataRoomFiles },
    isUserCurrentEditor,
  } = useOverview();

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor, pointerSensor);

  useEffect(() => {
    if (allFileDifferences?.size) {
      // Convert Map to an array of objects
      const fileDifferenceArray = Array.from(allFileDifferences.entries()).map(
        ([name, distance]) => {
          const newName = dataRoomFiles.find((file) => file.pdfUrl.endsWith(name))?.name || '';
          return {
            name: newName,
            distance: distance,
            s3Key: name,
          };
        },
      );
      // Sort the array by distance
      fileDifferenceArray.sort((a, b) => b.distance - a.distance);
      // Update the state with the sorted array
      setDisplayedFiles(fileDifferenceArray);
    }
  }, [allFileDifferences, dataRoomFiles]);

  const handlePdfViewerClose = () => {
    setIsPdfViewerOpen(false);
    resetFileUrl();
  };

  const toggleCheckbox = (file: string) => {
    if (comparingFiles.some((checkedFile) => checkedFile === file)) {
      setComparingFiles(comparingFiles.filter((checkedFile) => checkedFile !== file));
    } else {
      setComparingFiles([...comparingFiles, file]);
    }
  };

  const CheckBox = ({ file }: { index: number; file: string }) => {
    return (
      <div className="flex items-center justify-center" key={file}>
        <input
          type="checkbox"
          readOnly
          checked={comparingFiles.some((checkedFile) => checkedFile === file)}
          className={`size-4 cursor-pointer appearance-none border border-light-border bg-marveri-background bg-contain bg-center bg-no-repeat checked:bg-[url('@/assets/images/check-icon-white.svg')]`}
          data-testid="redline-diff-checkbox"
        />
      </div>
    );
  };

  const getDisplayNameFromFileName = useCallback(
    (filename: string) => {
      const findFile = dataRoomFiles.find((file) => file.name === filename);
      if (findFile) {
        return findFile.displayName;
      }

      return filename;
    },
    [dataRoomFiles],
  );

  const handleSidebarFileClick = (redlineFileName: string | undefined) => {
    const file = dataRoomFiles.find((file) => file.displayName === redlineFileName);
    if (file) {
      setSelectedFileName(file.name);
      setPdfDisplayName(file.displayName);
    } else if (redlineFileName) {
      setSelectedFileName(redlineFileName);
      setPdfDisplayName(getDisplayNameFromFileName(redlineFileName));
    }
  };

  const getDisplayNameFromS3Reference = useCallback(
    (fileS3Key: string) => {
      const findFile = dataRoomFiles.find((file) => file.pdfUrl.endsWith(fileS3Key));
      if (findFile) {
        return findFile.displayName;
      }

      return fileS3Key;
    },
    [dataRoomFiles],
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const dragFileTitle = event.active.data.current?.current;
      const dragFileDisplayTitle = getDisplayNameFromFileName(dragFileTitle);
      setDragFileName(dragFileDisplayTitle);
    },
    [getDisplayNameFromFileName],
  );

  const swapTemplateWithComparingFile = useCallback(
    (newTemplate: string) => {
      const findFile = dataRoomFiles.find((file) => file.name === newTemplate);
      const findName = dataRoomFiles.find(
        (file) => templateFileUrl && file.pdfUrl.endsWith(templateFileUrl),
      );
      setComparingFiles([...comparingFiles, findName?.name || '']);
      setTemplateFileUrl(findFile?.pdfUrl);
    },
    [comparingFiles, dataRoomFiles, setComparingFiles, setTemplateFileUrl, templateFileUrl],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const dragFileName = event.active.data.current?.current;
      if (event.over?.id === 'template-drop-zone') {
        swapTemplateWithComparingFile(dragFileName);
      }
    },
    [swapTemplateWithComparingFile],
  );

  const setTemplateToHovered = useCallback(
    (hoveredFile: string) => {
      swapTemplateWithComparingFile(hoveredFile);
    },
    [swapTemplateWithComparingFile],
  );

  useEffect(() => {
    const findFile = dataRoomFiles.find((file) => file.pdfUrl === templateFileUrl);
    if (findFile) {
      setDisplayName(findFile.displayName);
    } else {
      if (templateFileUrl) {
        setDisplayName(templateFileUrl);
      }
    }
  }, [dataRoomFiles, templateFileUrl]);

  const handleClusterButtonClick = () => {
    allFileDifferences?.clear();
    setDisplayedFiles([]);
    navigate(`../${redlineName}/clustering`);
  };

  return (
    <>
      <div className="flex h-[80vh] flex-col justify-between">
        <div className="flex items-center gap-4 border-b-2 border-light-border p-4 text-marveri-white">
          <span className="font-bold">Redline</span>
          {allFileDifferences && allFileDifferences?.size > 0 && loading && <LoadingSpinner />}
        </div>
        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} sensors={sensors}>
          <Droppable droppableId="template-drop-zone" data={{ current: templateFileUrl }}>
            <div
              className="border-b-2 border-light-border p-4 text-marveri-white"
              data-testid="baseline-container"
            >
              <h1
                className="pb-2 text-[12px] font-bold text-marveri-light-silver"
                data-testid="baseline-title"
              >
                Baseline Document
              </h1>
              <div
                className="h-[100px] overflow-y-auto scrollbar-thin scrollbar-thumb-marveri-muted-silver"
                data-testid="baseline-content"
              >
                <div
                  className="flex cursor-pointer flex-row items-center py-[10px]"
                  onClick={() => handleSidebarFileClick(displayName)}
                  onDoubleClick={() => setIsPdfViewerOpen(true)}
                  data-testid="baseline-display-name"
                >
                  <img src={FileIcon} className="mx-[10px] my-[3px] size-[16px]" />
                  <span className="select-none text-[12px]">{displayName}</span>
                </div>
              </div>
            </div>
          </Droppable>
          <div className="border-b-2 border-light-border p-4 text-marveri-white">
            <div className="flex justify-between pr-[5px] text-marveri-muted-silver">
              <h1 className="w-full pb-2 text-[12px] font-bold text-marveri-light-silver">
                Documents Compared
              </h1>
              <h2 className="pb-2 text-[12px] font-bold text-marveri-light-silver">Similarities</h2>
            </div>
            {allFileDifferences?.size === 0 ? (
              <div className="flex h-[calc(100vh-415px)] translate-y-10 justify-center">
                <LoadingSpinner size={20} />
              </div>
            ) : (
              <ScrollableDiv containerStyle="h-[calc(100vh-415px)]">
                {displayedFiles
                  .filter(
                    (file) =>
                      getDisplayNameFromFileName(file.name) !==
                      getDisplayNameFromS3Reference(templateFileUrl || ''),
                  )
                  .map((file, index) => (
                    <DraggableRedlineViewFile
                      key={index}
                      draggableId={index.toString()}
                      draggedFileName={file.name}
                      disabled={loading}
                    >
                      <div
                        className={classnames(
                          `relative flex min-h-[60px] cursor-pointer items-center justify-between gap-[14px] border-b border-light-border px-[10px] last:border-b-0 hover:bg-container-hover`,
                          focusedFiles.includes(file.s3Key)
                            ? {
                                'bg-[#8A3333]': focusedOperation === 'delete',
                                'bg-[#3E3E92]': focusedOperation === 'insert',
                              }
                            : {},
                        )}
                        onClick={() => toggleCheckbox(file.name)}
                        onContextMenu={(e: React.MouseEvent) => {
                          setHoveredFile(file.name);
                          setIsContextMenuVisible(true);
                          setPosition({
                            x: e.clientX,
                            y: e.clientY,
                          });
                          e.preventDefault();
                        }}
                      >
                        <CheckBox file={file.name} index={index} />

                        <div
                          className="ml-1 w-full py-[10px] text-[12px]"
                          onClick={(e) => {
                            handleSidebarFileClick(file.name);
                            e.stopPropagation();
                          }}
                          onDoubleClick={(event) => {
                            event.preventDefault();
                            setIsPdfViewerOpen(true);
                          }}
                          data-testid="redline-diff-file"
                        >
                          <div>
                            <div className="flex items-center gap-[12px] text-left">
                              <img src={FileIcon} alt="file-icon" className="h-[12px]" />
                              <span>{getDisplayNameFromFileName(file.name)}</span>
                            </div>
                          </div>
                        </div>
                        <div className="ml-1 py-[10px] text-[12px]">
                          {`${(file.distance * 100).toFixed(0)}%`}
                        </div>
                      </div>
                    </DraggableRedlineViewFile>
                  ))}
                <DragOverlay>
                  <div className="flex cursor-grabbing items-center gap-[8px] rounded border-2 border-light-border bg-marveri-background p-4 drop-shadow-lg">
                    {dragFileName}
                  </div>
                </DragOverlay>
              </ScrollableDiv>
            )}
          </div>
          <div className="flex h-[80vh] flex-col justify-between">
          {/* Export Button */}
          <div className="relative flex flex-col items-center">
            <div
              className="m-auto mt-2 h-[32px] w-[95px] cursor-pointer rounded border py-[3px] text-center text-white hover:bg-container-hover"
              onClick={handleExportClick}
              data-testid="export-button"
            >
              Export
            </div>
            {errorMessage && (
              <span className="mt-1 text-xs text-red-500">{errorMessage}</span>
            )}
          </div>

          {/* Clusters Button */}
          <div
            className="m-auto mt-2 h-[32px] w-[95px] cursor-pointer rounded border py-[3px] text-center text-white hover:bg-container-hover"
            onClick={handleClusterButtonClick}
            data-testid="diff-cluster-button"
          >
            Clusters
          </div>
      </div>
        </DndContext>
      </div>
      {isContextMenuVisible && (
        <ContextMenu
          disabled={!isUserCurrentEditor}
          options={[
            ...(hoveredFile
              ? [
                  {
                    title: 'Make Baseline Document',
                    onClick: () => setTemplateToHovered(hoveredFile),
                    icon: FileIcon,
                  },
                ]
              : []),
          ]}
          x={position.x}
          y={position.y}
        />
      )}
      {signedUrl && isPdfViewerOpen && selectedFileName && (
        <PdfViewer
          title={pdfDisplayName}
          fileUrl={signedUrl}
          multipleDocuments={false}
          closePdfViewer={handlePdfViewerClose}
        />
      )}
    </>
  );
};
