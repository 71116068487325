import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';

import ExitIcon from '@/assets/icons/gray-x-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';
import { CustomColumnType } from '@/pages/overview/contract-clauses/common/utils';
import { trpcReact } from '@/utils/trpc';

interface ClauseIdentificationRerunProps {
  rerunDialogVisibility: string | undefined;
  setRerunDialogVisibility: (val: string | undefined) => void;
  customColumnToEdit?: CustomColumnType;
}

export const ClauseIdentificationRerun = ({
  rerunDialogVisibility,
  setRerunDialogVisibility,
  customColumnToEdit,
}: ClauseIdentificationRerunProps) => {
  const { matter } = useOverview();
  const [clauseType, setClauseType] = useState(customColumnToEdit?.name || '');
  const [clauseDescription, setClauseDescription] = useState(customColumnToEdit?.description || '');
  const [clauseElements, setClauseElements] = useState(customColumnToEdit?.elements || '');
  const [otherInformation, setOtherInformation] = useState(customColumnToEdit?.other || '');

  const reRunCustomColumn = trpcReact.dataRoom.editAndRerunCustomContractClause.useMutation();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const initialValues = useRef({
    name: customColumnToEdit?.name || '',
    description: customColumnToEdit?.description || '',
    elements: customColumnToEdit?.elements || '',
    other: customColumnToEdit?.other || '',
  });

  useEffect(() => {
    const hasChanged =
      clauseType !== initialValues.current.name ||
      clauseDescription !== initialValues.current.description ||
      clauseElements !== initialValues.current.elements ||
      otherInformation !== initialValues.current.other;

    setIsButtonDisabled(!hasChanged);
  }, [clauseType, clauseDescription, clauseElements, otherInformation]);

  const handleCloseRerunDialog = () => {
    setRerunDialogVisibility(undefined);
  };

  const reRunClauseIdentification = () => {
    if (isButtonDisabled) return;
    if (customColumnToEdit) {
      reRunCustomColumn.mutate(
        {
          clientMatterId: matter.id,
          type: 'CLAUSE_IDENTIFICATION',
          name: clauseType,
          label: clauseType,
          elements: clauseElements,
          other: otherInformation,
          description: clauseDescription,
          response_format: '',
          customContractClauseDefinitionId: customColumnToEdit.id,
        },
        {
          onSuccess: () => handleCloseRerunDialog(),
          onError: () => {
            alert(`An error occurred please try again`);
          },
        },
      );
    }
  };

  return (
    <Dialog
      open={rerunDialogVisibility === 'identification'}
      onClose={handleCloseRerunDialog}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
    >
      <DialogPanel className="flex flex-col gap-8 rounded-[16px] bg-[#121112] p-8">
        <div className="flex justify-between">
          <DialogTitle className="text-[24px] font-medium text-[#EAE6EA]">Edit Column</DialogTitle>
          <MarveriIcon
            icon={ExitIcon}
            onClick={handleCloseRerunDialog}
            iconStyle=""
            iconType="other"
          />
        </div>

        <div className="flex max-w-[592px] flex-col gap-6" data-testid="clause-identification-step">
          <div className="text-[14px] font-medium text-[#EAE6EA]">
            {`Please provide details to help Marveri identify the clause. 'Clause Type' and 'Clause Description' are required fields. The more information you provide, the more accurate the results will be. Be sure to review all output carefully.`}
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Clause Type*</h2>
            <span className="text-[14px] font-normal text-N400">
              A brief label for the type of clause to be identified.
            </span>
            <input
              className="mt-2 h-[40px] resize-none rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500"
              value={clauseType}
              placeholder="e.g. Most Favored Nation"
              onChange={(event) => setClauseType(event.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Clause Description*</h2>
            <span className="text-[14px] font-normal text-N400">
              A description of the clause to be identified.
            </span>
            <textarea
              className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
              value={clauseDescription}
              placeholder='e.g. A "Most Favored Nation" clause is a provision that ensures one party (or those associated with one party) receives terms or benfits that are at least as favorable as those offered to any party in a similar situation; such clauses are designed to protect a party from being disadvantaged by ensuring that they will not be offered worse terms than others.'
              onChange={(event) => setClauseDescription(event.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Clause Elements</h2>
            <span className="text-[14px] font-normal text-N400">
              A list of key elements that characterize the clause to be identified.
            </span>
            <textarea
              className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
              value={clauseElements}
              placeholder="e.g. Provides that if a third party gets better terms on the licensing or sale of technology/goods/services under the contract must be entitled to those better terms."
              onChange={(event) => setClauseElements(event.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Other Information</h2>
            <span className="text-[14px] font-normal text-N400">
              Any additional information that may aid in identifying the relevant clause.
            </span>
            <textarea
              className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
              value={otherInformation}
              placeholder="e.g. Clauses that restrict pricing (or pricing changes) by reference to pricing offered to others should be considered a Most Favored Nations clause and flagged accordingly."
              onChange={(event) => setOtherInformation(event.target.value)}
            />
          </div>
          <div className="flex w-full justify-end gap-2">
            <div
              className="cursor-pointer rounded-full px-[18px] py-3 text-[14px] font-medium text-N200 hover:bg-[#292829] hover:text-[#EAE6EA]"
              onClick={handleCloseRerunDialog}
            >
              Cancel
            </div>
            <div
              className={`${isButtonDisabled ? 'cursor-default bg-[#353336]' : 'cursor-pointer bg-[#EAE6EA] hover:bg-N200 '} rounded-full px-[18px] py-3 text-[14px] font-medium text-[#121112]`}
              onClick={reRunClauseIdentification}
            >
              Save & Rerun
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
