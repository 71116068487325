import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@/assets/images/delete-icon.png';
import ElipseIcon from '@/assets/images/ellipsis.png';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useOverview } from '@/pages/overview/common/utils';
import { CreateRedlineModal } from '@/pages/overview/redline/listing/CreateRedlineModal';
import { deleteRedline } from '@/pages/overview/redline/utils';

export const RedlineListing = () => {
  const { matter, isUserCurrentEditor } = useOverview();
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const [isRedlineModalOpen, setIsRedlineModalOpen] = useState(false);

  const onDeleteRedline = async (redlineName: string) => {
    setProcessing(true);
    try {
      await deleteRedline(matter.number, matter.client.number, matter.id, redlineName);
    } finally {
      navigate('./');
      setProcessing(false);
    }
  };
  if (processing) {
    return (
      <div className="flex size-full cursor-progress items-center justify-center">
        <LoadingSpinner size={20} />
      </div>
    );
  }
  return (
    <div className="flex w-full flex-col overflow-x-hidden rounded-[12px] bg-marveri-background text-[12px] text-marveri-white">
      <h1 className="m-[16px] text-[34px] font-bold text-marveri-white">Prior Redlines</h1>
      <div className="flex flex-row flex-wrap overflow-y-auto ">
        {matter.redlines
          .filter((redline) => redline.clusters.length > 0)
          .map((redline) => (
            <div
              key={redline.name}
              className="group relative m-[16px] flex h-[160px] w-[280px] cursor-pointer flex-col
        items-center justify-center rounded-[5px] border-2 border-dark-border bg-marveri-background
        p-4 text-white hover:bg-container-hover"
              onClick={() => navigate(`${redline.name}/clustering`)}
            >
              <span className="text-[35px]"></span>
              <span className="text-[14px]">{redline.name}</span>
              <div
                className="group/elipsis absolute right-0 top-0 z-[2] m-2 hidden cursor-pointer rounded-[5px] border-[1.5px] border-[#696969] group-hover:block hover:border-[#282828] hover:bg-[#1b1b1b]"
                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
              >
                <img src={ElipseIcon} className="m-2 h-[20px]" />
                <div
                  className={`${!isUserCurrentEditor ? 'pointer-events-none' : ''} absolute right-[-9px] top-[65px] z-[3] m-2 hidden h-[40px] w-[130px] -translate-y-9 translate-x-[93px] cursor-pointer items-center rounded-[5px] border-2 border-[#282828] bg-[#1B1B1B] text-[14px] group-hover/elipsis:block hover:bg-[#313131]`}
                >
                  <span onClick={() => onDeleteRedline(redline.name)}>
                    <img src={DeleteIcon} className="m-2 inline-block h-[16px]" />
                    Delete
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>

      <CreateRedlineModal isModalOpen={isRedlineModalOpen} setIsModalOpen={setIsRedlineModalOpen} />
    </div>
  );
};
