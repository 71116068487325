import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';

import { useOverview } from '@/pages/overview/common/utils';
import { CustomColumnType } from '@/pages/overview/contract-clauses/common/utils';
import { trpcReact } from '@/utils/trpc';

interface DeleteCustomColumnProps {
  setIsDeleteModalVisible: (val: boolean) => void;
  isDeleteModalVisible: boolean;
  columnToDelete?: CustomColumnType;
}

export const DeleteCustomColumn = ({
  setIsDeleteModalVisible,
  isDeleteModalVisible,
  columnToDelete,
}: DeleteCustomColumnProps) => {
  const { matter } = useOverview();

  const deleteColumn = trpcReact.dataRoom.deleteCustomContractClause.useMutation();

  const handleDeleteColumn = () => {
    if (columnToDelete) {
      deleteColumn.mutate(
        {
          clientMatterId: matter.id,
          customContractClauseDefinitionId: columnToDelete?.id,
        },
        {
          onSuccess: handleCloseDeleteModal,
        },
      );
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };
  return (
    <Dialog
      open={isDeleteModalVisible}
      onClose={handleCloseDeleteModal}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      data-testid="delete-custom-column-modal"
    >
      <DialogPanel className="flex flex-col items-center gap-[17px] rounded-[12px] bg-[#292829] px-[20px] py-3">
        <DialogTitle className="text-[16px] font-semibold text-white">
          {`Delete "${columnToDelete?.name}" column?`}
        </DialogTitle>
        <div className="flex items-center gap-2 text-[12px] font-semibold">
          <div
            className="cursor-pointer rounded-full px-[18px] py-3 font-medium text-N200 hover:bg-[#292829] hover:text-[#EAE6EA]"
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </div>
          <div
            className="flex h-[30px] cursor-pointer items-center justify-center rounded-full bg-[#FF3B30] p-3 font-medium text-[#EAE6EA]"
            onClick={handleDeleteColumn}
          >
            Delete permanently
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
