import { DataRoomFile } from '@server/modules/client-matter/common/types';
import { AccessorFnColumnDef } from '@tanstack/react-table';

export type MAClause = {
  category: string;
  response: string;
  summary: string;
  citation: string | null;
  locations: number[];
  completed?: boolean;
  customContractClauseDefinitionId?: string | null;
};

export type MaClausesData = {
  id: string;
  sourceDocument: DataRoomFile;
  maClauses: MAClause[];
};

export type MAClauseOld = {
  category: string;
  response: string;
  citation: string | null;
  locations: number[];
};

export type MaClausesDataOld = {
  id: string;
  sourceDocument: DataRoomFile;
  maClauses: MAClauseOld[];
};

export type columnVisibilityType = {
  [key: string]: boolean;
};

export type CreateColumnFn = (
  key: string,
  wideCol?: boolean,
  iconCol?: boolean,
) => AccessorFnColumnDef<MaClausesData, MAClause | undefined>;

export type CreateCustomColumnFn = (
  customType: string,
  customName: string,
  customLabel: string,
  customDescription: string,
  customElements: string,
  otherText: string,
  wideCol: boolean,
) => void;

export type CustomColumnType = {
  id: string;
  name: string;
  type: string;
  label: string;
  description: string;
  elements: string | null;
  other: string | null;
  response_format: string | null;
};

export const columnInformation: Record<string, string> = {
  Status: 'Status of contract clause',
  Description: 'Description of file',
  'Expiration Date': 'On what date does the agreement expire by its terms?',
  'Renewal Period':
    'What is the renewal term that will apply following the expiration of the agreement, whether by automatic extension or unilateral extension by notice (if any)?',
  'Notice to Terminate Renewal':
    'What is the notice period required in order to terminate any automatic (or unilateral) renewal of the agreement (if applicable)?',
  'Termination for Convenience':
    'Is a party given the right to terminate the agreement solely by giving notice, without any need for cause or the satisfaction of other conditions (other than the passage of time / end of a waiting period)?',
  'Anti-Assignment':
    'Does the agreement require a party to obtain consent from, or provide notice to, another party (or parties) in connection with assigning the agreement to a third party?',
  'Change of Control':
    'Does the agreement require a party to obtain consent from, or provide notice to, another party (or parties) in connection with a change of control of such party? Does the agreement permit a counterparty to terminate the agreement in the event of such a corporate transaction? (Note the system will treat restrictions on assignment by operation of law as a change of control restriction).',
  'Non-Compete':
    'Does the agreement restrict a party from operating or competing with a counterparty in a specific geographic area, or a specific business or technology sector?',
  Exclusivity:
    'Does the agreement impose a requirement (whether during and/or following the term) that a party exclusively deal with a counterparty? (Note the system will flag as relevant: prohibitions on licensing or selling to third parties; prohibitions on collaboration or working with third parties; or “requirements” contracts requiring a party to procure all of its requirements for certain goods or services).',
  'Non-Solicit of Customers':
    'Does the agreement restrict a party from contracting with or soliciting customers or partners of a counterparty (whether during and/or following the term)?',
  'ROFO/ ROFR/ ROFN':
    'Does the agreement grant one party a right of first refusal, right of first offer or right of first negotiation to purchase, license, market or distribute equity interests, technology, assets, products or services?',
  'Most Favored Nation':
    'Does the agreement require that, in the event a party provides a third party with better terms on the sale / licensing of technology / goods / services described in the contract, the counter-party under the contract must be entitled to receive the same or better terms?',
  'Governing Law': 'What jurisdiction’s law governs the interpretation of the contract?',
};
