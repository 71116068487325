import { processingText } from '@/common/utils';
import { ProcessingTextLoader } from '@/components/ProcessingTextLoader';
import { LoadingDots } from '@/pages/overview/common/LoadingDots';

interface DataRoomLoaderProps {
  fileUploadPercent: number;
  loaderFocus: string;
  creationStep?: string;
}
export const DataRoomLoader = ({ loaderFocus, creationStep }: DataRoomLoaderProps) => {
  return (
    <div
      className="flex size-full flex-col items-center justify-center"
      data-testid="dataroom-loader"
    >
      {creationStep === 'submitting' ? (
        <div className="flex flex-col items-center justify-center">
          <LoadingDots />
          <div className="text-center text-marveri-light-silver">
            <p className="processing-text flex h-[37px] w-[284px] justify-center text-xl">
              Processing {loaderFocus}
            </p>
            <p>{`(Please do not close the page)`}</p>
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="flex flex-col items-center text-center text-marveri-light-silver">
              <p className="processing-text ml-4 flex h-[37px] w-[284px] justify-center text-xl">
                Processing Documents
              </p>
              <div className="flex items-center gap-2">
                <div className="w-[358px]  rounded-full bg-light-border">
                  <div
                    className="rounded-full bg-marveri-gold py-1 text-center text-xs leading-none text-white"
                    style={{ width: `5%` }}
                  ></div>
                </div>
                Loading...
              </div>
              <ProcessingTextLoader textArray={processingText} />
              <div className="mt-4 text-[14px] font-medium">
                <p className="mb-2">This may take a while.</p>
                <p>{`We will e-mail you when processing has completed.`}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
